import { useRoutes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./Components/layout";

import "./App.css";
import Home from "./Components/Home";
import Datenschutz from "./Components/Datenschutz";
import Impressum from "./Components/Impressum";

function App() {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/datenschutz", element: <Datenschutz /> },
    { path: "/impressum", element: <Impressum /> },
    // { path: "weiterbildung", element: <Education /> },
  ]);
  // return <div className="App">{element}</div>;
  return <Layout>{element}</Layout>;
}

export default App;
