import React from "react";

function Impressum() {
  return (
    <section id="impressum">
      <div className="container">
        <h1>Impressum</h1>
        <p>Traumfläche Giuseppe Nisi Liebigstrasse 14 73760 Ostfildern</p>
        <ul>
          <li>Telefon: +49 (0) 7158 189 816 5</li>
          <li>Telefax: +49 (0) 7158 189 816 7</li>
          <li>Mobil: +49 (0) 160 977 579 72</li>
          <li>E-Mail: info@traumflaeche.de</li>
          <li>
            {" "}
            <a href=" http://www.traumflaeche.de/" target="_blank" rel="noref">
              {" "}
              http://www.traumflaeche.de/
            </a>
          </li>
        </ul>

        <ul>
          <li>Steuernummer: 59295/06306</li>
          <li>USt.-Ident.-Nr.: DE 272651405</li>
        </ul>
      </div>
    </section>
  );
}

export default Impressum;
